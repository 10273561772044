const Vue = require('vue');
const { createApp, ref, computed } = Vue;

// Глобальная функция для корректного парсинга числовых значений
function parseVal(value) {
    if (value === null || value === undefined) return null;
    return Number(String(value).replace(',', '.'));
}

// Онлайн расчет времени на проветривание помещения
createApp({
    setup() {
        const Q = ref(null); // Производительность вентилятора, м³/ч
        const S = ref(null); // Площадь помещения, м²
        const h = ref(null); // Высота потолка, м

        const calculatedTime = computed(() => {
            const q = parseVal(Q.value);
            const s = parseVal(S.value);
            const hVal = parseVal(h.value);
            if (q && s && hVal && q > 0) {
                return Number(((s * hVal) / (q / 60)).toFixed(2));
            }
            return 0;
        });

        const airExchangeRate = computed(() => {
            if (calculatedTime.value > 0) {
                return Number((60 / calculatedTime.value).toFixed(2));
            }
            return 0;
        });

        return {
            Q,
            S,
            h,
            calculatedTime,
            airExchangeRate
        }
    }
}).mount('#vue-page-calculator-item__calculator__time')

// Онлайн расчет скорости воздуха в воздуховоде (Круглый воздуховод)
createApp({
    setup() {
        const L = ref(null); // Расход воздуха, м³/ч
        const d = ref(null); // Диаметр воздуховода, мм

        const airSpeed = computed(() => {
            const LVal = parseVal(L.value);
            const dVal = parseVal(d.value);
            if (LVal && dVal && dVal > 0) {
                return Number(((LVal * 4 * 1000000) / (3600 * 3.14 * (dVal ** 2))).toFixed(2));
            }
            return 0;
        });

        return {
            L,
            d,
            airSpeed
        }
    }
}).mount('#vue-page-calculator-item__calculator__speed-1')

// Онлайн расчет скорости воздуха в воздуховоде (Прямоугольный воздуховод)
createApp({
    setup() {
        const L = ref(null); // Расход воздуха, м³/ч
        const W = ref(null); // Ширина воздуховода, мм
        const H = ref(null); // Высота воздуховода, мм

        const airSpeed = computed(() => {
            const LVal = parseVal(L.value);
            const WVal = parseVal(W.value);
            const HVal = parseVal(H.value);
            if (LVal && WVal && HVal && WVal > 0 && HVal > 0) {
                return Number(((LVal * 1_000_000) / (3600 * WVal * HVal)).toFixed(2));
            }
            return 0;
        });

        return {
            L,
            W,
            H,
            airSpeed
        };
    }
}).mount('#vue-page-calculator-item__calculator__speed-2');


// Онлайн расчет и подбор сечения воздуховода вентиляции (Круглый)
createApp({
    setup() {
        const L = ref(null); // Расход воздуха, м³/ч
        const v = ref(null); // Скорость воздуха, м/с

        // Стандартные диаметры круглых воздуховодов (мм)
        const roundDucts = [100, 125, 150, 160, 200, 250, 315, 400, 500, 630];

        // Вычисление необходимой площади сечения
        const requiredArea = computed(() => {
            const LVal = parseVal(L.value);
            const vVal = parseVal(v.value);
            if (LVal && vVal && vVal > 0) {
                return Number((LVal / (3600 * vVal)).toFixed(4)); // м²
            }
            return 0;
        });

        // Поиск ближайшего подходящего круглого воздуховода
        const bestRoundDuct = computed(() => {
            if (!requiredArea.value) return null;

            let bestFit = null;
            let minDiff = Infinity;

            roundDucts.forEach(d => {
                const S = (Math.PI * (d / 1000) ** 2) / 4; // Площадь в м²
                const diff = Math.abs(S - requiredArea.value);

                if (diff < minDiff) {
                    minDiff = diff;
                    bestFit = { diameter: d, area: S.toFixed(4) };
                }
            });

            return bestFit;
        });

        return {
            L,
            v,
            bestRoundDuct
        };
    }
}).mount('#vue-page-calculator-item__calculator__section-1');


// Онлайн расчет и подбор сечения воздуховода вентиляции (Прямоугольный)
createApp({
    setup() {
        const L = ref(null); // Расход воздуха, м³/ч
        const v = ref(null); // Скорость воздуха, м/с

        // Стандартные размеры прямоугольных воздуховодов (Ширина x Высота в мм)
        const rectangularDucts = [
            { W: 100, H: 50 }, { W: 150, H: 75 }, { W: 200, H: 100 },
            { W: 250, H: 125 }, { W: 300, H: 150 }, { W: 400, H: 200 },
            { W: 500, H: 250 }, { W: 630, H: 315 }, { W: 250, H: 800 },
            { W: 300, H: 600 }, { W: 400, H: 500 }
        ];

        // Вычисление необходимой площади сечения
        const requiredArea = computed(() => {
            const LVal = parseVal(L.value);
            const vVal = parseVal(v.value);
            if (LVal && vVal && vVal > 0) {
                return Number((LVal / (3600 * vVal)).toFixed(4)); // м²
            }
            return 0;
        });

        // Поиск ближайших подходящих прямоугольных воздуховодов (3 варианта)
        const bestRectangularDucts = computed(() => {
            if (!requiredArea.value) return [];

            return rectangularDucts
                .map(({ W, H }) => {
                    const S = (W * H) / 1_000_000; // Площадь в м²
                    return { width: W, height: H, area: S.toFixed(4), diff: Math.abs(S - requiredArea.value) };
                })
                .sort((a, b) => a.diff - b.diff) // Сортируем по разнице в площади
                .slice(0, 3); // Берем 3 ближайших варианта
        });

        return {
            L,
            v,
            requiredArea,
            bestRectangularDucts
        };
    }
}).mount('#vue-page-calculator-item__calculator__section-2');


// Онлайн расчет воздухообмена в помещении (По количеству людей)
createApp({
    setup() {
        const peopleCount = ref(null); // Количество людей в помещении
        const activityLevel = ref(null); // Выбранный уровень активности

        // Варианты активности человека и нормы расхода воздуха (м³/ч)
        const activityOptions = window.page__CalculatorItem__activityOptions;

        // Вычисление воздухообмена
        const airExchange = computed(() => {
            const people = parseVal(peopleCount.value);
            const activity = parseVal(activityLevel.value);
            if (people && activity) {
                return people * activity;
            }
            return null;
        });

        return {
            peopleCount,
            activityLevel,
            activityOptions,
            airExchange
        };
    }
}).mount('#vue-page-calculator-item__calculator__air-exchange-1');

// Онлайн расчет воздухообмена в помещении (Расчет по кратности воздухообмена)
createApp({
    setup() {
        const area = ref(null); // Площадь помещения, м²
        const height = ref(null); // Высота потолка, м
        const roomType = ref(null); // Выбранное назначение помещения

        // Варианты помещений и их кратность воздухообмена (n)
        const roomOptions = window.page__CalculatorItem__roomOptions;

        // Вычисление воздухообмена
        const airExchange = computed(() => {
            const areaVal = parseVal(area.value);
            const heightVal = parseVal(height.value);
            const roomTypeVal = parseVal(roomType.value);
            if (areaVal && heightVal && roomTypeVal) {
                return Number((areaVal * heightVal * roomTypeVal).toFixed(2));
            }
            return null;
        });

        return {
            area,
            height,
            roomType,
            roomOptions,
            airExchange
        };
    }
}).mount('#vue-page-calculator-item__calculator__air-exchange-2');
